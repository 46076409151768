<template>
    <Setup-Step
        @restart="stage = 0"
        fluidContent
        :loadingMsg="loadingMsg"
        :settings="settings"
        :showRestart="stage != 0"
        :showSkip="stage == 0"
        step="courier-routes"
        title="Route Templates">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <!--list-->
                <v-tab-item>
                    <Setup-Block>
                        <p>
                            Planning transportation of consignments between locations can be achieved automatically by setting up regular routes.  
                            You can direct consignments destined for a particular area to travel via a particular warehouse or third party courier.  
                            You can also define general transit timing.  
                        </p>
                    
                        <BT-List
                            dense
                            :loadingMsg.sync="loadingMsg"
                            maxHeight="50vh"
                            navigation="routes"
                            :onPullSuccessAsync="pullRoutes"
                            :refreshToggle="refreshList">
                            <template v-slot="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.routeName }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-row no-gutters class="my-1">
                                            <v-icon class="mx-1" :class="item.isAutoDeliver ? 'success--text' : 'grey--text'" x-small title="Auto Deliver">mdi-robot</v-icon>
                                            <v-icon class="mx-1" :class="item.canBundle ? 'success--text' : 'grey--text'" x-small :title="item.canBundle ? 'Can Bundle' : 'No Bundling'">mdi-group</v-icon>
                                            <span v-if="item.canBundle && item.bundleName != null" class="mx-1" title="Bundle Name">{{ item.bundleName }}</span>
                                        </v-row>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-row no-gutters>
                                        <BT-Btn leftIcon="mdi-pencil" small @click="addNew(item)" />
                                        <BT-Btn leftIcon="mdi-delete" small @click="remove(item)" />
                                    </v-row>
                                </v-list-item-action>
                            </template>
                        </BT-List>

                        <BT-Btn
                            icon="mdi-map-marker-radius"
                            label="Add Direct Route"
                            @click="addDirectRoute" />

                        <BT-Btn
                            icon="mdi-map-marker-path"
                            label="Add Multi-Leg Route"
                            @click="addMultiRoute" />

                        <!-- <p class="my-4">Would you like to add a route?</p> -->
                    </Setup-Block>
                    
                </v-tab-item>

                <!--route name-->
                <v-tab-item v-if="newItem != null">
                    <Setup-Block>
                        <BT-Field-String
                            label="Route Name"
                            v-model="newItem.routeName"
                            isEditing />
                        
                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Next"
                            @click="nextName" />
                    </Setup-Block>
                </v-tab-item>

                <!--path-->
                <v-tab-item v-if="newItem != null">
                    <Setup-Block>
                        <p>What path would you like consignments in this route to take?</p>

                        <BT-List
                            dense
                            :items="newItem.movements"
                            :loadingMsg.sync="loadingMsg"
                            maxHeight="50vh">
                            <template v-slot="{ item }">
                                <v-list-item-content>
                                    {{ item }}
                                </v-list-item-content>
                                <!-- <v-list-item-action>
                                    <v-row no-gutters>
                                        <BT-Btn leftIcon="mdi-pencil" small @click="addNew(item)" />
                                        <BT-Btn leftIcon="mdi-delete" small @click="remove(item)" />
                                    </v-row>
                                </v-list-item-action> -->
                            </template>
                        </BT-List>

                        <BT-List-Item-Check
                            @change="addMovement"
                            class="my-3"
                            subtitle="Add a leg to this route."
                            title="Add Movement" />

                    </Setup-Block>
                </v-tab-item>

                <!--all pointers-->
                <v-tab-item>
                    <v-row no-gutters>
                        <v-col cols="auto" class="my-1">
                            <span class="mx-4">
                                <v-icon :color="colorFrom">mdi-square-opacity</v-icon>Departures
                            </span>
                        </v-col>
                        <v-col cols="auto" class="my-1">
                            <span class="mx-4">
                                <v-icon :color="colorTo">mdi-square-opacity</v-icon>Destinations
                            </span>
                        </v-col>
                    </v-row>
                    <GmapMap
                        ref="locMap"
                        :center="center"
                        :zoom="7"
                            style="width: 100%; height: 60vh;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">
                            
                            <div v-for="(pointer, ind) in otherPointers" :key="ind + 'from'">
                                <gmap-polygon
                                    v-if="pointer.boundary != null" />
                                
                            </div>
                            <gmap-polygon
                                v-for="(pointer, index) in otherPointers"
                                :fillOpacity="0.5"
                                :key="index + 'from'"
                                :paths="pointer.boundaryFrom"
                                :strokeColor="colorFrom"
                                :strokeWeight="1" />

                            <gmap-polygon
                                v-for="(pointer, index) in otherPointers"
                                :fillOpacity="0.5"
                                :key="index + 'to'"
                                :paths="pointer.boundaryTo"
                                :strokeColor="colorTo"
                                :strokeWeight="1" />

                            <gmap-polygon
                                v-if="newItem != null"
                                @paths_changed="($event) => { updatePointerPaths($event, newItem, 'boundaryFrom') }"
                                clickable
                                editable
                                :fillOpacity="0.5"
                                :paths="newItem.boundaryFrom"
                                :strokeColor="colorFrom"
                                :strokeWeight="1" />

                            <gmap-polygon
                                v-if="newItem != null"
                                @paths_changed="($event) => { updatePointerPaths($event, newItem, 'boundaryTo') }"
                                clickable
                                editable
                                :fillOpacity="0.5"
                                :paths="newItem.boundaryTo"
                                :strokeColor="colorTo"
                                :strokeWeight="1" />
                    </GmapMap>

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Next"
                        @click="stage += 1" />
                    <BT-List-Item-Check
                        @change="showLegOptions = true"
                        class="my-3"
                        subtitle="Add a leg to this route."
                        title="Add Movement" />

                    <BT-List-Item-Check
                        v-if="!isLengthyArray(newItem.movements) || showLegOptions"
                        @change="nextDeliveryStraight"
                        class="my-3"
                        subtitle="Just straight to the destination.  No in between stops."
                        title="Deliver Straight To Destination (Single Leg Path)" />

                    <BT-List-Item-Check
                        v-if="!isLengthyArray(newItem.movements) || showLegOptions"
                        @change="nextDeliveryVia"
                        class="my-3"
                        subtitle="Travels via at least one other location before arriving at final destination."
                        title="Deliver Via (Multiple Legs In Path)" />

                    <BT-List-Item-Check
                        @change="showOtherOptions = true"
                        class="my-3"
                        subtitle="A geographical area for orders that we will deliver ourselves."
                        title="Self Courier" />

                    <v-slide-x-transition>
                        <BT-List-Item-Check
                            v-if="!showOtherOptions"
                            @change="showOtherOptions = true"
                            class="my-3"
                            subtitle="A geographical area for orders that we will deliver ourselves."
                            title="Self Courier" />
                    </v-slide-x-transition>
                </v-tab-item>

                <!--to pointers-->
                <v-tab-item>
                    <Setup-Block>
                        <p>Next you need to define what destinations this route covers.</p>
                    
                        <BT-List
                            dense
                            :items="toPointers"
                            maxHeight="50vh">
                            <template v-slot="{ item }">
                                <v-list-item-icon>
                                    <v-icon v-if="item.boundary != null" class="my-auto">mdi-texture-box</v-icon>
                                    <v-icon v-else class="my-auto">mdi-map-marker</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span v-if="item.location != null">{{ item.location | toFamiliarLocationLine }}</span>
                                        <span v-else>{{ item.pointerName }}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <BT-Chips-Menu
                                            v-model="item.weekdays"
                                            canOpen
                                            column
                                            isEditing
                                            :items="weekdayOptions()"
                                            multiple
                                            returnCSV
                                            xSmall />
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-row no-gutters>
                                        <BT-Btn leftIcon="mdi-pencil" small @click="addNew(item)" />
                                        <BT-Btn leftIcon="mdi-delete" small @click="remove(item)" />
                                    </v-row>
                                </v-list-item-action>
                            </template>
                        </BT-List>
                        
                        <p>Would you like to add a destination pointer?</p>

                        <v-row no-gutters>
                            <BT-Btn
                                buttonClass="primary mx-1"
                                :disabled="isLengthyArray(toPointers)"
                                inline
                                label="No" />

                            <BT-Btn
                                buttonClass="primary mx-1"
                                inline
                                label="Yes"
                                @click="addToPointer" />
                        </v-row>
                    </Setup-Block>
                </v-tab-item>

                <!--to options-->
                <v-tab-item>
                    <Setup-Block>
                        <BT-List-Item-Check
                            v-if="!showCustomerLocations && !showCourierLocations && !showMyLocations"
                            @change="addToArea"
                            class="my-3"
                            icon="mdi-texture-box"
                            subtitle="A geographical area that covers destinations within it."
                            title="Area" />

                        <BT-List-Item-Check
                            v-if="!showCustomerLocations && !showCourierLocations"
                            @change="showMyLocations = true"
                            class="my-3"
                            icon="mdi-map-marker"
                            subtitle="Destinations to one of my own locations."
                            title="My Location" />

                        <BT-List-Item-Check
                            v-if="!showMyLocations && !showCourierLocations"
                            @change="showCustomerLocations = true"
                            class="my-3"
                            icon="mdi-map-marker"
                            subtitle="Destinations to one of my customers' specific locations."
                            title="Customer Location" />

                        <BT-List-Item-Check
                            v-if="!showMyLocations && !showCustomerLocations"
                            @change="showCourierLocations = true"
                            class="my-3"
                            icon="mdi-map-marker"
                            subtitle="Destinations to one of my courier's specific locations."
                            title="Courier Location" />
                    </Setup-Block>
                </v-tab-item>

                <!--area to-->
                <v-tab-item>
                    <p>The area that destinations are in to take this route.</p>

                    <GmapMap
                        ref="locMap"
                        :center="center"
                        :zoom="7"
                            style="width: 100%; height: 60vh;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">

                            <gmap-polygon
                                v-for="(pointer, index) in otherPointers"
                                :fillOpacity="0.5"
                                :key="index + 'from'"
                                :paths="pointer.boundaryFrom"
                                :strokeColor="colorFrom"
                                :strokeWeight="1" />

                            <gmap-polygon
                                v-for="(pointer, index) in otherPointers"
                                :fillOpacity="0.5"
                                :key="index + 'to'"
                                :paths="pointer.boundaryTo"
                                :strokeColor="colorTo"
                                :strokeWeight="1" />

                            <gmap-polygon
                                v-if="newItem != null"
                                @paths_changed="($event) => { updatePointerPaths($event, newItem, 'boundaryFrom') }"
                                clickable
                                editable
                                :fillOpacity="0.5"
                                :paths="newItem.boundaryFrom"
                                :strokeColor="colorFrom"
                                :strokeWeight="1" />

                            <gmap-polygon
                                v-if="newItem != null"
                                @paths_changed="($event) => { updatePointerPaths($event, newItem, 'boundaryTo') }"
                                clickable
                                editable
                                :fillOpacity="0.5"
                                :paths="newItem.boundaryTo"
                                :strokeColor="colorTo"
                                :strokeWeight="1" />
                    </GmapMap>

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Next"
                        @click="stage += 1" />

                </v-tab-item>

                <!--from weekday-->
                <v-tab-item v-if="newFromPointer != null">
                    <p>And does this apply always or only certain days of the week?</p>

                    <BT-Select-List
                        :canSearch="false"
                        :items="weekdayOptions()"
                        itemText=""
                        v-model="newFromPointer.weekdays"
                        returnCSV />

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Save"
                        @click="saveFromPointer" />
                    
                </v-tab-item>

                <!--to weekday-->
                <v-tab-item v-if="newToPointer != null">
                    <p>And does this apply always or only certain days of the week?</p>

                    <BT-Select-List
                        :canSearch="false"
                        :items="weekdayOptions()"
                        itemText=""
                        v-model="newToPointer.weekdays"
                        returnCSV />

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Save"
                        @click="saveToPointer" />
                    
                </v-tab-item>

                <!--from location selector-->
                <v-tab-item>
                    <Setup-Block>
                        <p>This route applies to deliveries departing from which of your locations?</p>

                        <BT-Select-List
                            @change="selectFromLocation"
                            :loadingMsg.sync="loadingMsg"
                            maxHeight="50vh"
                            :multiple="false"
                            navigation="locations"
                            returnObject
                            v-model="newFromPointer.locationID">
                            <template v-slot="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item | toLocationLine }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </BT-Select-List>
                    </Setup-Block>
                </v-tab-item>

                <!--direct to area-->
                <v-tab-item>
                    <p>The area this route covers for destinations.</p>

                    <GmapMap
                        ref="locMap"
                        :center="center"
                        :zoom="7"
                            style="width: 100%; height: 60vh;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">

                            <div v-for="(pointer, index) in pointers" :key="index + 'a'">
                                <gmap-polygon
                                    v-if="pointer.boundary != null && pointer.id != newToPointer.id"
                                    :fillOpacity="0.5"
                                    :paths="pointer.boundary"
                                    :strokeColor="pointer.isDestination ? colorTo : colorFrom"
                                    :strokeWeight="1" />

                                    <!-- :icon="pointer.isDestination ? { fillColor: colorTo } : { fillColor: colorFrom }" -->
                                <gmap-marker 
                                    v-else-if="pointer.location != null" 
                                    :fillColor="colorTo"
                                    :strokeColor="colorTo"
                                    :position="{ lat: pointer.location.lat, lng: pointer.location.lng }" />

                                <gmap-polygon
                                    v-if="newToPointer != null && newToPointer.boundary != null"
                                    :paths="newToPointer.boundary"
                                    :strokeColor="colorTo"
                                    :strokeWeight="2"
                                    :fillOpacity="0.5"
                                    @paths_changed="($event) => { updatePointerPaths($event, newToPointer, 'boundary') }"
                                    editable />

                            </div>
                    </GmapMap>

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Save"
                        @click="saveToPointer" />
                </v-tab-item>
            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Route-Setup',
    components: {
        BTChipsMenu: () => import('~components/BT-Chips-Menu.vue'),
        BTListItemCheck: () => import('~components/BT-List-Item-Check.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue'),
    },
    data() {
        return {
            addingDirectRoute: false,
            addingMultiRoute: false,
            center: { lat: -38, lng: 144 }, //
            colorFrom: '#006841',
            colorTo: '#cc0000',
            fromPointers: [], //
            loadingMsg: null, //
            newItem: {}, //
            newMovement: null, //
            newFromPointer: {},
            newToPointer: {},
            otherPointers: [],
            pointers: [],
            refreshList: false, //
            refreshFromList: false, //
            refreshToList: false, //
            showCourierLocations: false,
            showCustomerLocations: false,
            showMyLocations: false,
            showLegOptions: false,
            showOtherLeavingOptions: false,
            showOtherOptions: false,
            stage: 0, //
            toPointers: [], //
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        addDirectRoute() {
            this.addNew();
            this.addingDirectRoute = true;
        },
        addMultiRoute() {
            this.addNew();
            this.addingMultiRoute = true;
        },
        addMovement() {
            this.newMovement = {

            };
        },
        async addNew(j) {
            this.addingDirectRoute = false;
            this.addingMultiRoute = false;

            if (j != null && j.id != null) {
                j = await this.$BlitzIt.store.get('routes', j.id, null, true);
                this.pointers = await this.$BlitzIt.store.getAll('route-pointers', { routeID: j.id, includeDetails: true }, true);
            }

            this.newItem = j || {
                id: null,
                rowVersion: null,
                routeName: null,
                startCanPickup: false,
                startCanDropoff: false,
                endCanPickup: false,
                endCanDropoff: false,
                canBundle: false,
                bundleName: null,
                isRestrictedBundling: false,
                isFixed: false,
                lastLegMinutesToArrival: 0,
                isAutoDeliver: false,
                movements: []
            };

            if (this.newItem.id != null) {
                if (this.isArrayOfLength(this.pointers, 2) &&
                    this.pointers.some(x => !x.isDestination && x.locationID != null && x.boundary == null) &&
                    this.pointers.some(x => x.isDestination && x.locationID == null && x.boundary != null)) {
                    this.addingDirectRoute = true;
                }
                else {
                    this.addingMultiRoute = true;
                }
            }

            this.stage += 1;
        },
        addToPointer() {
            this.showMyLocations = false;
            this.showCourierLocations = false;
            this.showCustomerLocations = false;
            this.stage += 1;
        },
        addToArea() {
            this.newToPointer = {
                id: null,
                rowVersion: null,
                courierRouteID: this.newItem.id,
                pointerName: 'Area',
                isDestination: true,
                boundary: this.getAreaAround(this.center, 1),
                locationID: null,
                location: null,
                weekdays: 'Always'
            };

            this.stage += 1;
        },
        addToCustomerLocation() {

        },
        addToCourierLocation() {

        },
        addToMyLocation() {

        },
        async nextDeliveryStraight() {
            this.newItem.startCanDropoff = true;
            this.newItem.startCanPickup = true;
            this.newItem.endCanDropoff = true;
            this.newItem.endCanPickup = true;
            this.newItem.canBundle = false;
            this.newItem.bundleName = null;
            this.newItem.isAutoDeliver = false;
            this.newItem.isFixed = false;
            this.newItem.isRestrictedBundling = false;
            this.newItem.lastLegMinutesToArrival = 0;
            this.newItem.movements = [];
            //go to To Coverage
            // await this.save(2);
            await this.save(0);

            if (!this.settings.data.courieringForOthers) {
                //expect departure from one of my locations
                var locations = await this.$BlitzIt.store.getAll('locations');

                if (this.isArrayOfLength(locations, 1)) {
                    //save departure pointer
                    this.newFromPointer = {
                        id: null,
                        rowVersion: null,
                        courierRouteID: this.newItem.id,
                        pointerName: null,
                        isDestination: false,
                        boundary: this.getAreaAround(this.center, 1),
                        locationID: locations[0].id,
                        location: locations[0],
                        weekdays: 'Always'
                    };

                    await this.saveFromPointer();

                    this.stage += 2;
                }
                else {
                    this.stage += 1;
                }
            }
            else {
                this.stage += 1;
            }
        },
        nextDeliveryVia() {
            this.newItem.movements.push({
                id: null,
                sortOrder: this.newItem.movements.length,
                courierLocationID: null,
                courierAgreementID: null,
                isFixed: false,
                weekdays: 'Always',
                minuteOfDay: 0,
                minutesToArrival: 0,
                minutesToDeparture: 0,
                isAutoDeliver: false
            });
        },
        async nextName() {
            if (this.addingDirectRoute) {
                if (this.newItem.id != null) {
                    //patching
                    await this.save(0);
                    this.newToPointer = this.pointers.find(x => x.isDestination && x.boundary != null);
                    this.stage = 10;
                }
                else {
                    await this.save(0);
                    var locations = await this.$BlitzIt.store.getAll('locations');

                    this.newFromPointer = {
                        id: null,
                        rowVersion: null,
                        courierRouteID: this.newItem.id,
                        pointerName: null,
                        isDestination: false,
                        boundary: null,
                        locationID: null,
                        location: null,
                        weekdays: 'Always'
                    };

                    if (this.isArrayOfLength(locations, 1)) {
                        //save departure pointer
                        this.newFromPointer.locationID = locations[0].id;
                        this.newFromPointer.location = locations[0];

                        await this.saveFromPointer();

                        this.newToPointer = {
                            id: null,
                            rowVersion: null,
                            courierRouteID: this.newItem.id,
                            pointerName: null,
                            isDestination: true,
                            boundary: this.getAreaAround(locations[0], 1),
                            locationID: null,
                            location: null,
                            weekdays: 'Always'
                        };

                        this.stage = 10;
                    }
                    else {
                        this.stage = 9;
                    }
                }
            }
            else {
                if (!this.isLengthyArray(this.newItem.movements)) {
                    this.stage += 2;
                }
                else {
                    this.stage += 1;
                }
            }
            // else if (this.addingMultiRoute) {
            //     this.stage += 1;
            // }
        },
        async pullPointers(list) {
            if (this.settings.data.courieringForSelf &&
                !this.settings.data.courieringForOthers &&
                !this.settings.data.courieringByOthers) {
                if (!this.isLengthyArray(list)) {
                    //add pointer
                    this.newItem = this.$BlitzIt.tracker.track({
                        id: null,
                        rowVersion: null,
                        boundaryFrom: this.getAreaAround(this.center, 0.5),
                        boundaryTo: this.getAreaAround(this.center, 1),
                        courierAgreementID: null,
                        preferStartPickup: false,
                        preferEndDropoff: false,
                        isSelfCourier: true,
                        weekdays: 'Always'
                    }, {
                        trackProps: ['boundaryFrom', 'boundaryTo', 'courierAgreementID', 'preferStartPickup', 'preferEndDropoff', 'isSelfCourier', 'weekdays']
                    });

                    this.stage = 1;
                    this.isSelfCourier = true;
                }
                else if (this.isArrayOfLength(list, 1)) {
                    var p = list[0];
                    if (p.isSelfCourier == true) {
                        this.newItem = this.$BlitzIt.tracker.track(p, {
                            trackProps: ['boundaryFrom', 'boundaryTo', 'courierAgreementID', 'preferStartPickup', 'preferEndDropoff', 'isSelfCourier', 'weekdays']
                        });
                    }
                    this.stage = 1;
                    this.isSelfCourier = true;
                }
                else {
                    this.stage = 0;
                }
            }
            else {
                this.stage = 0;
            }

            this.pointers = list;

            return list;
        },
        async pullRoutes(list) {
            //var d = this.settings.data;

            return list;
        },
        async remove(item) {
            try {
                this.loadingMsg = 'Deleting';
                await this.$BlitzIt.store.delete('routes', item.id);
                this.refreshList = !this.refreshList;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async removeFromPointer(item) {
            try {
                this.loadingMsg = 'Deleting';
                await this.$BlitzIt.store.delete('route-pointers', item.id);
                this.refreshFromList = !this.refreshFromList;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async removeToPointer(item) {
            try {
                this.loadingMsg = 'Deleting';
                await this.$BlitzIt.store.delete('route-pointers', item.id);
                this.refreshToList = !this.refreshToList;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async resetPointers() {
            try {
                this.loadingMsg = 'Loading Pointers';
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async save(stepNum = 1) {
            try {
                this.loadingMsg = 'Saving';

                if (this.newItem != null) {
                    var res = null;

                    if (this.newItem.id == null) {
                        res = await this.$BlitzIt.store.post('routes', this.newItem);
                    }
                    else {
                        res = await this.$BlitzIt.store.patch('routes', this.newItem);
                    }

                    this.newItem.id = res.id;
                    this.newItem.rowVersion = res.rowVersion;
                    this.$BlitzIt.tracker.reset(this.newItem);
                }

                this.stage += stepNum;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async saveFromPointer() {
            try {
                this.loadingMsg = 'Saving';

                if (this.newFromPointer != null) {
                    var res = null;

                    if (this.newFromPointer.id == null) {
                        res = await this.$BlitzIt.store.post('route-pointers', this.newFromPointer);
                        this.pointers.push(this.newFromPointer);
                    }
                    else {
                        res = await this.$BlitzIt.store.patch('route-pointers', this.newFromPointer);
                    }

                    this.newFromPointer.id = res.id;
                    this.newFromPointer.rowVersion = res.rowVersion;
                }

                if (this.addingDirectRoute) {
                    this.stage += 1;
                }
                else {
                    this.stage = 4;
                }
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async saveToPointer() {
            try {
                this.loadingMsg = 'Saving';

                if (this.newToPointer != null) {
                    var res = null;

                    if (this.newToPointer.id == null) {
                        res = await this.$BlitzIt.store.post('route-pointers', this.newToPointer);
                        this.pointers.push(this.newToPointer);
                    }
                    else {
                        res = await this.$BlitzIt.store.patch('route-pointers', this.newToPointer);
                    }

                    this.newToPointer.id = res.id;
                    this.newToPointer.rowVersion = res.rowVersion;
                }

                if (this.addingDirectRoute) {
                    this.refreshList = !this.refreshList;
                    this.stage = 0;
                }
                else {
                    this.stage = 4;
                }
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async selectFromLocation(l) {
            if (l != null) {
                this.newFromPointer.locationID = l.id;
                this.newFromPointer.location = l;
                await this.saveFromPointer();

                //select to location
                this.newToPointer = {
                    id: null,
                    rowVersion: null,
                    courierRouteID: this.newItem.id,
                    pointerName: null,
                    isDestination: true,
                    boundary: this.getAreaAround(l, 1),
                    locationID: null,
                    location: null,
                    weekdays: 'Always'
                };
            }
        },
        updatePointerPaths(mvcArray, zone, prop) {
            let paths = [];
            for (let i=0; i<mvcArray.getLength(); i++) {
              let path = [];
              for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({lat: point.lat(), lng: point.lng()});
              }
              paths.push(path);
            }

            zone[prop] = paths[0];

            zone.changed = true;
        },
    }
}
</script>